import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useExperimentIsVariant } from "@capone/experiments";
import { ActionButton, Icon } from "halifax";
import { SupportConnectorProps } from "./container";
import {
  SUPPORT_TEXT_OFFERS,
  SUPPORT_TEXT_WALLET,
  SUPPORT_URL,
} from "../../textConstants";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import "./styles.scss";

export interface ISupportProps
  extends SupportConnectorProps,
    RouteComponentProps {}

export const Support = ({}: ISupportProps) => {
  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  return (
    <Box className="support-container">
      <Icon name="service-icon" className="support-icon" />
      <Typography className="support-body">
        {isTravelWalletCreditsExperiment
          ? SUPPORT_TEXT_WALLET(!isCorpHideTravelOffers)
          : SUPPORT_TEXT_OFFERS}
      </Typography>
      <ActionButton
        className="support-action-button"
        message="Contact support"
        onClick={() => window.open(SUPPORT_URL, "_blank")?.focus()}
      />
    </Box>
  );
};
