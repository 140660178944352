import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { getRewardsAccounts } from "../../../../modules/rewards/reducer";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
  getTravelWalletCreditHistory,
} from "../../../../modules/travel-wallet/reducer";
import { IStoreState } from "../../../../reducers/types";
import { Credits } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  credit: getTravelWalletCredit(state),
  creditBreakdown: getCreditBreakdown(state),
  rewardsAccounts: getRewardsAccounts(state),
  creditTransactionHistory: getTravelWalletCreditHistory(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CreditsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCredits = connector(withRouter(Credits));
