export const PAGE_LABEL = "Capital One Travel Sale";
export const HEADER_TITLE_SALE = "Enjoy savings, earn rewards!";

export const HEADER_SUBTITLE_SALE =
  "Save up to $200 on select hotels with our first-ever travel sale.";

export const SALES_EVENT_LEADUP_SUBTITLE =
  "Our first-ever travel sale lands in";
export const SALES_EVENT_LEADUP_CTA = "Explore upcoming sale";

const formatOfferNumber = (x: number) => `(${x})`;
const formatOfferRemainingOutOfTotalNumber = (
  remaining: number,
  total: number,
  showRemainingText: boolean
) => `(${remaining} of ${total}${showRemainingText ? ` remaining` : ""})`;

export const FEATURED_DEALS = (x: number) =>
  `Featured deals ${x ? formatOfferNumber(x) : ""}`;
export const HOTEL_DEALS = (x: number) =>
  `Hotel deals ${x ? formatOfferNumber(x) : ""}`;
export const FLIGHT_DEALS = (x: number) =>
  `Flight deals ${x ? formatOfferNumber(x) : ""}`;
export const CAR_DEALS = (x: number) =>
  `Car deals ${x ? formatOfferNumber(x) : ""}`;
export const FAQ = "Sale FAQs";

export const DEALS_REMAINING = (
  remaining: number,
  total: number,
  showRemainingText: boolean
) =>
  `${formatOfferRemainingOutOfTotalNumber(
    remaining,
    total,
    showRemainingText
  )}`;

export const getEarnRateText = (earnRate: number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";

  return `${earnRate}${multiplier} ${currency}`;
};

export const FEATURED_DEAL_TITLE = (isMobile?: boolean) =>
  `Featured ${isMobile ? "" : "hotel and flight"} deals`;
export const HOTEL_DEAL_TITLE = (
  earnRate?: number,
  currency?: string,
  isMobile?: boolean
) => {
  if (!earnRate || !currency) return "Hotel deals";

  return isMobile
    ? `Earn ${getEarnRateText(
        earnRate,
        currency
      )} and save big on hotels in these destinations`
    : `<strong>Earn ${getEarnRateText(
        earnRate,
        currency
      )}</strong> and save big on hotels in these destinations`;
};
export const FLIGHT_DEAL_TITLE = (
  earnRate?: number,
  currency?: string,
  isMobile?: boolean
) => {
  if (!earnRate || !currency) return "Flight deals";

  return isMobile
    ? `Earn ${getEarnRateText(
        earnRate,
        currency
      )} and save big on flights to these destinations`
    : `<strong>Earn ${getEarnRateText(
        earnRate,
        currency
      )}</strong> and save big on flights to these destinations`;
};

export const CAR_DEAL_TITLE = (
  earnRate?: number,
  currency?: string,
  isMobile?: boolean
) => {
  if (!earnRate || !currency) return "Car deals";

  return isMobile
    ? `Earn ${getEarnRateText(earnRate, currency)} and save big on cars`
    : `<strong>Earn ${getEarnRateText(
        earnRate,
        currency
      )}</strong> and save big on cars`;
};

export const FILTER_BY = "Filter by:";
export const LOCATION_TYPE = "Location type";

export const FILTER_NAMES = {
  travelSaleInternationalDestinations: "🌎 International Destinations",
  travelSaleUSDestinations: "🇺🇸 US Destinations",
  travelSaleBeachDestinations: "🏖️ Beach Destinations",
  travelSaleWinterDestinations: "❄️ Winter Destinations",
};
