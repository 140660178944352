// TODO: relocate client specific language; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
import { cardsToShowLastFourOnRewardsBanner } from "redmond";

export const HOME_PAGE_BANNER_TEXT = ({
  firstName,
  earnRate,
  flightEarnRate,
  carEarnRate,
  productId,
  lastFour,
  currency,
}: {
  firstName: string;
  earnRate: string | number;
  flightEarnRate: string | number;
  carEarnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
}) => {
  let earnString =
    flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you book with your ${productId} account.`
      : flightEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and ${earnRate}X ${currency} on hotels and rental cars when you book with your ${productId} account.`
      : `Hi ${firstName}, earn ${earnRate}X ${currency} on hotels and rental cars when you book with your ${productId} account.`;
  earnString += cardsToShowLastFourOnRewardsBanner.includes(
    productId.toLowerCase()
  )
    ? `ending in ${lastFour}`
    : ``;
  return earnString;
};

export const HOTELS_SHOPPING_FUNNEL_BANNER_TEXT = ({
  firstName,
  earnRate,
  flightEarnRate,
  carEarnRate,
  productId,
  lastFour,
  currency,
}: {
  firstName: string;
  earnRate: string | number;
  flightEarnRate: string | number;
  carEarnRate: string | number;
  productId: string;
  lastFour: string;
  currency: string;
}) => {
  let earnString =
    flightEarnRate && carEarnRate && flightEarnRate === carEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and rental cars when you book with your ${productId} account.`
      : flightEarnRate
      ? `Hi ${firstName}, earn ${flightEarnRate}X ${currency} on flights and ${earnRate}X ${currency} on hotels and rental cars when you book with your ${productId} account.`
      : `Hi ${firstName}, earn ${earnRate}X ${currency} on hotels and rental cars when you book with your ${productId} account.`;
  earnString += cardsToShowLastFourOnRewardsBanner.includes(
    productId.toLowerCase()
  )
    ? `ending in ${lastFour}`
    : ``;
  return earnString;
};
