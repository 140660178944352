import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { Funnel, TravelWalletOffer } from "redmond";
import { isOfferSoldOut, sortTravelSaleDestinations } from "halifax";
import { config } from "../../../../api/config";
import dayjs from "dayjs";

export const getTravelWalletOffers = (
  state: IStoreState
): TravelWalletOffer[] => state.wallet.offers;

export const getSelectedFilter = (state: IStoreState): string | undefined =>
  state.wallet.selectedOfferFilter;

export const getSearchLocation = (state: IStoreState) =>
  state.wallet.searchLocation;
export const getFetchTravelWalletDetailsCallState = (state: IStoreState) =>
  state.wallet.fetchTravelWalletDetailsCallState;

export const getActiveTravelWalletOffers = createSelector(
  getTravelWalletOffers,
  (offers) => {
    return offers.filter((offer) => {
      return dayjs().isAfter(dayjs(offer?.saleEventStartOn));
    });
  }
);

export const getFilteredTravelOffers = createSelector(
  getTravelWalletOffers,
  getSelectedFilter,
  getSearchLocation,
  (travelWalletOffers, selectedFilter, searchLocation) => {
    if (selectedFilter) {
      const filterDestinations: string[] = searchLocation
        ? config.filters[selectedFilter]?.filter(
            (destination: string) => destination === searchLocation
          )
        : config.filters[selectedFilter];
      if (filterDestinations.length > 0) {
        const filteredOffers: TravelWalletOffer[] = [];
        travelWalletOffers.forEach((offer) => {
          if (filterDestinations.includes(offer.descriptions[2])) {
            filteredOffers.push(offer);
          }
        });
        return filteredOffers;
      }
    }

    return searchLocation
      ? travelWalletOffers.filter(
          (offer) => offer.descriptions[2] === searchLocation
        )
      : travelWalletOffers;
  }
);

const sortDestinations = sortTravelSaleDestinations(
  config.travelSaleSortedDestinations
);

export const getTravelWalletOffersByFunnel = createSelector(
  getFilteredTravelOffers,
  (travelWalletOffers) => {
    const travelWalletOffersByFunnel = {
      Air: Array<TravelWalletOffer>(),
      Lodging: Array<TravelWalletOffer>(),
      Ground: Array<TravelWalletOffer>(),
      Home: Array<TravelWalletOffer>(),
    };
    travelWalletOffers.forEach((offer) => {
      if (offer.funnels.includes(Funnel.Air)) {
        travelWalletOffersByFunnel[Funnel.Air].push(offer);
      }
      if (offer.funnels.includes(Funnel.Lodging)) {
        travelWalletOffersByFunnel[Funnel.Lodging].push(offer);
      }
      if (offer.funnels.includes(Funnel.Ground)) {
        travelWalletOffersByFunnel[Funnel.Ground].push(offer);
      }
      if (offer.funnels.includes(Funnel.Home)) {
        travelWalletOffersByFunnel[Funnel.Home].push(offer);
      }
    });

    travelWalletOffersByFunnel[Funnel.Air].sort((a, b) =>
      sortDestinations(a.descriptions[2], b.descriptions[2])
    );
    travelWalletOffersByFunnel[Funnel.Lodging].sort((a, b) =>
      sortDestinations(a.descriptions[2], b.descriptions[2])
    );
    travelWalletOffersByFunnel[Funnel.Ground].sort((a, b) =>
      sortDestinations(a.descriptions[2], b.descriptions[2])
    );
    travelWalletOffersByFunnel[Funnel.Home].sort((a, b) =>
      sortDestinations(a.descriptions[2], b.descriptions[2])
    );
    return travelWalletOffersByFunnel;
  }
);

export const getTravelWalletCredit = (state: IStoreState) =>
  state.wallet.credit;

export const getCreditBreakdown = (state: IStoreState) =>
  state.wallet.creditBreakdown;

export const getTravelWalletCreditHistory = (state: IStoreState) =>
  state.wallet.creditHistory;

export const getLocationSearchString = (state: IStoreState) =>
  state.wallet.searchString;

export const getLocationCategories = createSelector(
  getTravelWalletOffers,
  getSelectedFilter,
  (offers, selectedFilter) => {
    const filterDestinations: string[] = [];

    if (selectedFilter) {
      filterDestinations.push(...config.filters[selectedFilter]);
    }

    const offerLocations = offers
      .filter(
        (offer) =>
          !isOfferSoldOut(offer, config.travelSaleSoldOutHotelDestinations)
      )
      .map((offer) => offer.descriptions[2]);
    const uniqueOfferLocations = Array.from(new Set(offerLocations));
    const filteredUniqueOfferLocations = !!filterDestinations.length
      ? uniqueOfferLocations.filter((location) =>
          filterDestinations.includes(location)
        )
      : uniqueOfferLocations;

    const groupedOfferLocations = filteredUniqueOfferLocations.reduce(
      (groupedDestinations, curr) => {
        const groupName =
          Object.keys(config.travelSaleSearchContinents).find((continent) =>
            config.travelSaleSearchContinents[continent].includes(curr)
          ) || "Other";

        const existingGroupedDestination = groupedDestinations.find(
          (groupedDestination) => groupedDestination.label === groupName
        );

        if (existingGroupedDestination) {
          existingGroupedDestination.results.push({ label: curr });
        } else {
          groupedDestinations.push({
            label: groupName,
            results: [{ label: curr }],
            category: "",
          });
        }

        return groupedDestinations;
      },
      [] as { label: string; results: { label: string }[]; category: string }[]
    );

    const sortOrder = Object.keys(config.travelSaleSearchContinents);
    const sortedContinentGroups = groupedOfferLocations.sort((a, b) => {
      if (
        sortOrder?.indexOf(a.label) < sortOrder?.indexOf(b.label) ||
        b.label === "Other"
      )
        return -1;
      if (
        sortOrder?.indexOf(a.label) < sortOrder?.indexOf(b.label) ||
        a.label === "Other"
      )
        return 1;
      return 0;
    });

    return sortedContinentGroups.map((continent) => {
      continent.results.sort((a, b) => sortDestinations(a.label, b.label));
      return continent;
    });
  }
);

export const getLocationCategoriesFilteredBySearchString = createSelector(
  getLocationCategories,
  getLocationSearchString,
  (
    locationCategories,
    searchString
  ): { label: string; results: { label: string }[]; category: string }[] => {
    if (locationCategories) {
      if (!searchString) {
        return locationCategories;
      }

      return locationCategories.map((locationCategory) => ({
        ...locationCategory,
        results: locationCategory.results.filter((result) =>
          result.label?.toLowerCase().includes(searchString.toLowerCase())
        ),
      }));
    }

    return [];
  }
);
