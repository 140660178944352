import { connect, ConnectedProps } from "react-redux";

import { MobileTravelSaleSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { getSearchLocation } from "../../../../modules/travel-wallet/reducer";
import { actions } from "../../../../modules/travel-wallet/actions";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getSearchLocation(state),
});

export const mapDispatchToProps = {
  setSearchLocation: actions.setSearchLocation,
  setLocationSearchString: actions.setLocationSearchString,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileTravelSaleSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileTravelSaleSearchControl = withRouter(
  connector(MobileTravelSaleSearchControl)
);
