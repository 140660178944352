import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentState } from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments
export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";
export const TRAVEL_WALLET_CREDITS_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";
export const TRAVEL_CREDIT_HISTORY_EXPERIMENT =
  "c1-marketplace-credit-transaction-history";
export const CREDIT_OFFER_STACKING_V1 =
  "c1-marketplace-credit-offer-stacking-v1";
export const ANNUAL_TRAVEL_CREDITS = "c1-marketplace-annual-travel-credits";
export const TRAVEL_SALE = "c1-marketplace-travel-sale";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
export type DefaultVariantType = typeof CONTROL | typeof AVAILABLE;

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => {
    if (exp.id === experimentId) {
      return exp;
    }
    return;
  });

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: string
): DefaultVariantType {
  return getExperimentVariantCustomVariants(
    experiments,
    experimentId,
    DEFAULT_VARIANTS
  );
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result) => {
          setState(result as ExperimentState);
        });
      };
      fetchExperiments();
    }
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
