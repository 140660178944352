import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { getFetchRewardsAccountsCallState } from "../../../../modules/rewards/reducer";
import {
  getActiveTravelWalletOffers,
  getTravelWalletOffers,
} from "../../../../modules/travel-wallet/reducer";
import { IStoreState } from "../../../../reducers/types";
import { getAreOfferDetailsLoading } from "../../reducer";
import { OffersList } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  allOffers: getTravelWalletOffers(state),
  activeOffers: getActiveTravelWalletOffers(state),
  areOfferDetailsLoading: getAreOfferDetailsLoading(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OffersListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedOffersList = connector(withRouter(OffersList));
