import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { TravelSaleLanding } from "./component";
import {
  getFetchTravelWalletDetailsCallState,
  getFilteredTravelOffers,
  getSearchLocation,
  getSelectedFilter,
  getTravelWalletOffersByFunnel,
} from "../../modules/travel-wallet/reducer";
import {
  clearSelectedFilters,
  fetchTravelWalletDetails,
  selectFilter,
} from "../../modules/travel-wallet/actions/actions";
import { getSelectedAccount } from "../../modules/rewards/reducer";
import { fetchRewardsAccounts } from "../../modules/rewards/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    filteredTravelOffers: getFilteredTravelOffers(state),
    offersByFunnel: getTravelWalletOffersByFunnel(state),
    selectedFilter: getSelectedFilter(state),
    searchLocation: getSearchLocation(state),
    fetchTravelWalletDetailsCallState:
      getFetchTravelWalletDetailsCallState(state),
    selectedRewardsAccount: getSelectedAccount(state),
  };
};

const mapDispatchToProps = {
  fetchTravelWalletDetails,
  selectFilter,
  clearSelectedFilters,
  fetchRewardsAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelSaleLandingConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTravelSaleLanding = connector(
  withRouter(TravelSaleLanding)
);
