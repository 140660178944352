import { actions, actionTypes } from "../actions";
import {
  CreditDetail,
  Transaction,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";

export interface IWalletState {
  offers: TravelWalletOffer[];
  credit?: TravelWalletCredit;
  fetchTravelWalletDetailsCallState: CallState;
  creditHistory: Transaction[];
  creditBreakdown: CreditDetail[];
  fetchTravelWalletCreditHistoryCallState: CallState;
  searchString: string;
  searchLocation: string;
  selectedOfferFilter?: string;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

export const initialState: IWalletState = {
  offers: [],
  credit: undefined,
  fetchTravelWalletDetailsCallState: CallState.NotCalled,
  creditHistory: [],
  creditBreakdown: [],
  fetchTravelWalletCreditHistoryCallState: CallState.NotCalled,
  searchString: "",
  searchLocation: "",
  selectedOfferFilter: undefined,
};

export function reducer(
  state: IWalletState = initialState,
  action: actions.TravelWalletActions
): IWalletState {
  switch (action.type) {
    case actionTypes.FETCH_TRAVEL_WALLET_DETAILS:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchTravelWalletDetailsCallState: CallState.Failed,
      };

    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };

    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
        creditBreakdown: action.creditBreakdown,
        fetchTravelWalletDetailsCallState: CallState.Success,
      };
    case actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY:
      return {
        ...state,
        fetchTravelWalletCreditHistoryCallState: CallState.InProcess,
      };
    case actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED:
      return {
        ...state,
        fetchTravelWalletCreditHistoryCallState: CallState.Failed,
      };
    case actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY:
      return {
        ...state,
        creditHistory: action.creditHistory,
        fetchTravelWalletCreditHistoryCallState: CallState.Success,
      };
    case actionTypes.SELECT_FILTER:
      return {
        ...state,
        selectedOfferFilter: action.filter,
        searchString: "",
        searchLocation: "",
      };
    case actionTypes.CLEAR_SELECTED_FILTER:
      return {
        ...state,
        selectedOfferFilter: undefined,
      };
    case actionTypes.SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
        searchLocation: "",
      };
    case actionTypes.SET_SEARCH_LOCATION:
      return {
        ...state,
        searchLocation: action.location?.label || "",
        searchString: action.location?.label || "",
      };
    default:
      return state;
  }
}

export * from "./selectors";
