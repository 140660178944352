import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";

import { MobileLocationSearchInput } from "./component";
import {
  getLocationCategories,
  getLocationSearchString,
  getSearchLocation,
} from "../../../../../../modules/travel-wallet/reducer";
import {
  setLocationSearchString,
  setSearchLocation,
} from "../../../../../../modules/travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getSearchLocation(state),
  locationSearchString: getLocationSearchString(state),
  locationCategories: getLocationCategories(state),
});

export const mapDispatchToProps = {
  setSearchLocation,
  setLocationSearchString,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSearchInputConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSearchInput = connector(
  MobileLocationSearchInput
);
