import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getLocationCategoriesFilteredBySearchString,
  getSearchLocation,
} from "../../../../../../modules/travel-wallet/reducer";
import { actions } from "../../../../../../modules/travel-wallet/actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";

export const mapDispatchToProps = {
  setValue: actions.setSearchLocation,
  fetchValueCategories: actions.setLocationSearchString,
};

export const mapStateToProps = (state: IStoreState) => {
  const searchLocation = getSearchLocation(state);
  return {
    value: searchLocation
      ? {
          label: searchLocation,
        }
      : null,
    valueCategories: getLocationCategoriesFilteredBySearchString(state),
    callbackOnEmptyValue: true,
  };
};

export const ConnectedLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<{ label: string } | null>
  ) => JSX.Element
);
