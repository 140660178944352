// Getters
export const FETCH_TRAVEL_WALLET_DETAILS =
  "travelWallet/FETCH_TRAVEL_WALLET_DETAILS";
export type FETCH_TRAVEL_WALLET_DETAILS = typeof FETCH_TRAVEL_WALLET_DETAILS;

// Setters
export const SET_TRAVEL_WALLET_OFFERS = "travelWallet/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_TRAVEL_WALLET_CREDIT = "travelWallet/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  "travelWallet/SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED";
export type SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  typeof SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;

export const FETCH_TRAVEL_WALLET_CREDIT_HISTORY =
  "travelWallet/FETCH_TRAVEL_WALLET_CREDIT_HISTORY";
export type FETCH_TRAVEL_WALLET_CREDIT_HISTORY =
  typeof FETCH_TRAVEL_WALLET_CREDIT_HISTORY;

export const SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED =
  "travelWallet/SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED";
export type SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED =
  typeof SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED;

export const SET_TRAVEL_WALLET_CREDIT_HISTORY =
  "travelWallet/SET_TRAVEL_WALLET_CREDIT_HISTORY";
export type SET_TRAVEL_WALLET_CREDIT_HISTORY =
  typeof SET_TRAVEL_WALLET_CREDIT_HISTORY;

export const SELECT_FILTER = "travelSale/SELECT_FILTER";
export type SELECT_FILTER = typeof SELECT_FILTER;

export const SET_SEARCH_STRING = "travelSale/SET_SEARCH_STRING";
export type SET_SEARCH_STRING = typeof SET_SEARCH_STRING;

export const SET_SEARCH_LOCATION = "travelSale/SET_SEARCH_LOCATION";
export type SET_SEARCH_LOCATION = typeof SET_SEARCH_LOCATION;

export const CLEAR_SELECTED_FILTER = "travelSale/CLEAR_SELECTED_FILTER";
export type CLEAR_SELECTED_FILTER = typeof CLEAR_SELECTED_FILTER;
