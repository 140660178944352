import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { TravelWalletLandingConnectorProps } from "./container";
import "./styles.scss";
import {
  B2BSpinner,
  CurrencyFormatters,
  LoadingPopup,
  SecondaryHeader,
  PageTabNavigations,
  getShouldShowAnnualTravelCreditBanner,
  TravelSalesEventBanner,
} from "halifax";
import { useExperimentIsVariant } from "@capone/experiments";
import clsx from "clsx";
import { OffersList } from "./components/OffersList";
import { FAQ } from "./components/FAQ";
import { Box } from "@material-ui/core";
import * as textConstants from "./textConstants";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import { Support } from "./components/Support";
import { useInView } from "react-intersection-observer";
import { Credits } from "./components/Credits";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../context/experiments";
import { BASE_PATH_HOME, PATH_TRAVEL_SALE } from "../../utils/paths";

export interface ITravelWalletLandingProps
  extends TravelWalletLandingConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

export const TravelWalletLanding = ({
  fetchTravelWalletDetails,
  allOffers,
  activeOffers,
  credit,
  creditBreakdown,
  areOfferDetailsLoading,
  history,
  fetchTravelWalletCreditHistory,
  rewardsAccounts,
  fetchRewardsAccounts,
}: ITravelWalletLandingProps) => {
  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = React.useMemo(
    () => travelCreditHistoryExperiment === AVAILABLE,
    [travelCreditHistoryExperiment]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = React.useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const { shouldShowVentureXBanner, shouldShowVentureBanner } =
    getShouldShowAnnualTravelCreditBanner(
      rewardsAccounts,
      creditBreakdown,
      180
    );
  const shouldShowAnnualTravelCreditBanner =
    isAnnualTravelCreditsExperiment &&
    (shouldShowVentureXBanner || shouldShowVentureBanner);

  const offers =
    travelSaleVariant === TRAVEL_SALE_LEAD_UP ? activeOffers : allOffers;

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const [clickedSectionId, setClickedSectionId] = useState<string>();

  const [creditsRef, creditsInView] = useInView({ threshold: 0.4 });
  const [offersRef, offersInView] = useInView();
  const [faqRef, faqInView] = useInView();
  const [mobileSectionToDisplay, setMobileSectionToDisplay] = useState<
    "credits" | "offers"
  >("credits");

  const scrollToSection = (sectionId: string) => {
    if (!matchesMobile) {
      const element = document.getElementById(sectionId);

      const BANNER_HEIGHT =
        document
          .getElementById("b2bportal-banner-container")
          ?.getBoundingClientRect().height ?? 0;
      const HEADER_HEIGHT =
        document.querySelector(".app-header")?.getBoundingClientRect().height ??
        0;
      const MARGIN = 20;
      const NAV_HEIGHT =
        document.querySelector(".page-nav")?.getBoundingClientRect().height ??
        0;

      const yOffset =
        window.pageYOffset -
        (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN + NAV_HEIGHT);

      const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;

      setClickedSectionId(sectionId);
      setTimeout(() => {
        setClickedSectionId(undefined);
      }, 5000);
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const { matchesMobile } = useDeviceTypes();

  const selectedTab = React.useMemo(() => {
    if (
      creditsInView &&
      (clickedSectionId ? clickedSectionId === "#credits" : true)
    ) {
      return 0;
    }
    if (faqInView && (clickedSectionId ? clickedSectionId === "#faq" : true)) {
      return isTravelWalletCreditsExperiment ? 2 : 1;
    }
    if (
      offersInView &&
      (clickedSectionId ? clickedSectionId === "#offers" : true)
    ) {
      return isTravelWalletCreditsExperiment ? 1 : 0;
    }

    return undefined;
  }, [creditsInView, offersInView, faqInView]);

  useEffect(() => {
    fetchTravelWalletDetails();
    fetchRewardsAccounts();
  }, []);

  useEffect(() => {
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, [isTravelCreditHistoryExperiment]);

  useEffect(() => {
    if (history.location.hash === "#faq" && !areOfferDetailsLoading) {
      scrollToSection("faq");
    }
    if (history.location.hash === "#offers" && !areOfferDetailsLoading) {
      scrollToSection("offers");
    }
  }, [history, areOfferDetailsLoading]);

  const headerBreadCrumbLinks = [
    {
      onClick: () => {
        history.push(BASE_PATH_HOME);
      },
      label: "Search",
    },
    {
      label: isTravelWalletCreditsExperiment
        ? textConstants.REDESIGN_HEADER_TITLE_WALLET(!isCorpHideTravelOffers)
        : textConstants.REDESIGN_HEADER_TITLE_OFFER,
    },
  ];
  return (
    <Box
      className={clsx("travel-wallet-landing-root", { mobile: matchesMobile })}
    >
      {areOfferDetailsLoading && (
        <LoadingPopup
          open={areOfferDetailsLoading}
          indicator={B2BSpinner}
          message={
            isTravelWalletCreditsExperiment
              ? textConstants.LOADING_WALLET
              : textConstants.LOADING_OFFERS
          }
          classes={[
            "offers-loading-modal",
            ...(matchesMobile ? ["mobile"] : []),
          ]}
        />
      )}
      <SecondaryHeader
        title={
          isTravelWalletCreditsExperiment
            ? textConstants.REDESIGN_HEADER_TITLE_WALLET(
                !isCorpHideTravelOffers
              )
            : textConstants.REDESIGN_HEADER_TITLE_OFFER
        }
        subtitle={
          matchesMobile
            ? undefined
            : isTravelWalletCreditsExperiment
            ? textConstants.HEADER_SUBTITLE_WALLET(!isCorpHideTravelOffers)
            : textConstants.HEADER_SUBTITLE_OFFERS
        }
        breadCrumbLinks={headerBreadCrumbLinks}
        className="travel-wallet"
        rightContent={
          travelSaleVariant !== CONTROL ? (
            <TravelSalesEventBanner
              variant={
                travelSaleVariant === TRAVEL_SALE_ACTIVE
                  ? "default"
                  : "with-timer"
              }
              onClick={() => {
                const path = `${PATH_TRAVEL_SALE}?entryType=my_travel_credits_offers`;
                matchesMobile
                  ? history.push(path)
                  : window.open(path, "_blank");
              }}
              subtitle={
                travelSaleVariant === TRAVEL_SALE_ACTIVE
                  ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE
                  : textConstants.TRAVEL_SALES_EVENT_LEADUP_SUBTITLE
              }
              buttonText={
                travelSaleVariant === TRAVEL_SALE_ACTIVE
                  ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA
                  : textConstants.TRAVEL_SALES_EVENT_LEADUP_CTA
              }
            />
          ) : undefined
        }
      />
      {matchesMobile ? (
        isTravelWalletCreditsExperiment ? (
          <PageTabNavigations
            navItems={[
              ...(isTravelWalletCreditsExperiment
                ? [
                    {
                      label: `Travel credits`,
                      onClick: () => setMobileSectionToDisplay("credits"),
                    },
                  ]
                : []),
              ...(!isCorpHideTravelOffers
                ? [
                    {
                      label: `Travel offers${
                        offers && offers.length > 0 ? ` (${offers.length})` : ""
                      }`,
                      onClick: () => setMobileSectionToDisplay("offers"),
                    },
                  ]
                : []),
            ]}
            className="mobile"
          />
        ) : null
      ) : (
        <PageTabNavigations
          navItems={[
            ...(isTravelWalletCreditsExperiment
              ? [
                  {
                    label: `My travel credits${
                      credit
                        ? ` (${CurrencyFormatters.get(
                            credit.amount.currency
                          ).format(credit.amount.amount * -1)})`
                        : ""
                    }`,
                    iconName: "piggy-bank-icon",
                    onClick: () => scrollToSection("credits"),
                  },
                ]
              : []),
            ...(!isCorpHideTravelOffers
              ? [
                  {
                    label: `My travel offers${
                      offers && offers.length > 0 ? ` (${offers.length})` : ""
                    }`,
                    iconName: "offer-tag",
                    onClick: () => scrollToSection("offers"),
                  },
                ]
              : []),
            {
              label: "Frequently asked questions",
              iconName: "question-circle",
              onClick: () => scrollToSection("faq"),
            },
          ]}
          selectedTab={selectedTab}
          ariaLabel="travel offers tabs"
        />
      )}
      {matchesMobile && travelSaleVariant !== CONTROL && (
        <TravelSalesEventBanner
          variant={
            travelSaleVariant === TRAVEL_SALE_ACTIVE ? "default" : "with-timer"
          }
          onClick={() =>
            history.push(
              `${PATH_TRAVEL_SALE}?entryType=my_travel_credits_offers`
            )
          }
          subtitle={
            travelSaleVariant === TRAVEL_SALE_ACTIVE
              ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE
              : textConstants.TRAVEL_SALES_EVENT_LEADUP_SUBTITLE
          }
          buttonText={
            travelSaleVariant === TRAVEL_SALE_ACTIVE
              ? textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA
              : textConstants.TRAVEL_SALES_EVENT_LEADUP_CTA
          }
          isMobile
        />
      )}

      {!areOfferDetailsLoading && (
        <>
          {(matchesMobile ? mobileSectionToDisplay === "credits" : true) &&
            isTravelWalletCreditsExperiment && (
              <div id="credits" ref={creditsRef}>
                <Credits
                  isTravelCreditHistoryExperiment={
                    isTravelCreditHistoryExperiment
                  }
                  isCreditAndOfferStackingExperimentV1={
                    isCreditAndOfferStackingExperimentV1
                  }
                  showAnnualCreditBanner={shouldShowAnnualTravelCreditBanner}
                  isAnnualTravelCreditsExperiment={
                    isAnnualTravelCreditsExperiment
                  }
                />
              </div>
            )}
          {(matchesMobile && isTravelWalletCreditsExperiment
            ? mobileSectionToDisplay === "offers"
            : true) &&
            (travelSaleVariant !== TRAVEL_SALE_ACTIVE || !matchesMobile) &&
            !isCorpHideTravelOffers && (
              <div id="offers" ref={offersRef}>
                <OffersList />
              </div>
            )}
          <div id="faq" ref={faqRef}>
            <FAQ />
          </div>
          <div id="support" className={matchesMobile ? "mobile" : ""}>
            <Support />
          </div>
        </>
      )}
    </Box>
  );
};
