import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { MobileLocationSearchModal } from "./component";
import {
  setLocationSearchString,
  setSearchLocation,
} from "../../../../../../modules/travel-wallet/actions/actions";
import {
  getLocationCategoriesFilteredBySearchString,
  getSearchLocation,
} from "../../../../../../modules/travel-wallet/reducer";

export const mapDispatchToProps = {
  setSearchLocation,
  setLocationSearchString,
};

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getSearchLocation(state),
  filteredLocationCategories:
    getLocationCategoriesFilteredBySearchString(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSearchModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSearchModal = connector(
  MobileLocationSearchModal
);
