import React from "react";
import { MobileTravelSaleSearchControlConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {
  MobileLocationSearchInput,
  MobileLocationSearchModal,
} from "./components";

interface ITravelSaleSearchControlProps
  extends MobileTravelSaleSearchControlConnectorProps,
    RouteComponentProps {
  className?: string;
}

export const MobileTravelSaleSearchControl =
  ({}: ITravelSaleSearchControlProps) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    return (
      <div className="mobile-travel-sale-search-control-root">
        <MobileLocationSearchInput
          onClick={() => setModalOpen(true)}
          modalOpen={modalOpen}
        />
        <MobileLocationSearchModal open={modalOpen} setOpen={setModalOpen} />
      </div>
    );
  };
