import { getConfigTenant } from "@capone/common";

// Use process.env when running Pawtucket modules on their own, otherwise use runtime config from McLean
export const BASE_URL = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_TYSONS_URL
  : "";

export const TRAVEL_SALE_FEATURED_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_FEATURED_DESTINATIONS || [];
export const TRAVEL_SALE_SORTED_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_SORTED_DESTINATIONS || [];
export const TRAVEL_SALE_INTERNATIONAL_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_INTERNATIONAL_DESTINATIONS || [];
export const TRAVEL_SALE_US_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_US_DESTINATIONS || [];
export const TRAVEL_SALE_BEACH_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_BEACH_DESTINATIONS || [];
export const TRAVEL_SALE_WINTER_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_WINTER_DESTINATIONS || [];
export const TRAVEL_SALE_SEARCH_CONTINENTS =
  window.__mclean_env__.TRAVEL_SALE_SEARCH_CONTINENTS || {};
export const TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS =
  window.__mclean_env__.TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS || [];
export const TRAVEL_SALE_INITIAL_OFFER_COUNTS = window.__mclean_env__
  .TRAVEL_SALE_INITIAL_OFFER_COUNTS || {
  FEATURED: 0,
  HOTELS: 0,
  FLIGHTS: 0,
  CARS: 0,
};

const TENANT = getConfigTenant();

export const config = {
  baseURL: BASE_URL!,
  travelSaleFeaturedDestinations: TRAVEL_SALE_FEATURED_DESTINATIONS,
  travelSaleSearchContinents: TRAVEL_SALE_SEARCH_CONTINENTS,
  travelSaleSortedDestinations: TRAVEL_SALE_SORTED_DESTINATIONS,
  travelSaleSoldOutHotelDestinations: TRAVEL_SALE_SOLD_OUT_HOTEL_DESTINATIONS,
  travelSaleInitialOfferCounts: TRAVEL_SALE_INITIAL_OFFER_COUNTS,
  filters: {
    travelSaleInternationalDestinations: TRAVEL_SALE_INTERNATIONAL_DESTINATIONS,
    travelSaleUSDestinations: TRAVEL_SALE_US_DESTINATIONS,
    travelSaleBeachDestinations: TRAVEL_SALE_BEACH_DESTINATIONS,
    travelSaleWinterDestinations: TRAVEL_SALE_WINTER_DESTINATIONS,
  },
  TENANT,
};
