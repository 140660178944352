import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { Support } from "./component";

const mapStateToProps = (_: IStoreState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SupportConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedSupport = connector(withRouter(Support));
