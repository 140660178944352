import * as actionTypes from "./constants";
import {
  TravelWalletOffer,
  TravelWalletCredit,
  Transaction,
  CreditDetail,
} from "redmond";

export const fetchTravelWalletDetails = (): IFetchTravelOffersDetails => ({
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
});

export interface IFetchTravelOffersDetails {
  type: actionTypes.FETCH_TRAVEL_WALLET_DETAILS;
}

export const fetchTravelWalletDetailsCallStateFailed =
  (): IFetchTravelWalletDetailsCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED,
  });

export interface IFetchTravelWalletDetailsCallStateFailed {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;
}

export const setTravelWalletOffers = (
  offers: TravelWalletOffer[]
): ISetTravelWalletOffers => ({
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS,
  payload: offers,
});

export interface ISetTravelWalletOffers {
  type: actionTypes.SET_TRAVEL_WALLET_OFFERS;
  payload: TravelWalletOffer[];
}

export const setTravelWalletCredit = (
  creditBreakdown: CreditDetail[],
  credit?: TravelWalletCredit
): ISetTravelWalletCredit => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT,
  credit,
  creditBreakdown,
});

export interface ISetTravelWalletCredit {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT;
  credit?: TravelWalletCredit;
  creditBreakdown: CreditDetail[];
}

export const fetchTravelWalletCreditHistory =
  (): IFetchTravelWalletCreditHistory => ({
    type: actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY,
  });

export interface IFetchTravelWalletCreditHistory {
  type: actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY;
}

export const fetchTravelWalletCreditHistoryCallStateFailed =
  (): IFetchTravelWalletCreditHistoryCallStateFailed => ({
    type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED,
  });

export interface IFetchTravelWalletCreditHistoryCallStateFailed {
  type: actionTypes.SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILED;
}

export const setTravelWalletCreditHistory = (
  creditHistory: Transaction[]
): ISetTravelWalletCreditHistory => ({
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY,
  creditHistory,
});

export interface ISetTravelWalletCreditHistory {
  type: actionTypes.SET_TRAVEL_WALLET_CREDIT_HISTORY;
  creditHistory: Transaction[];
}

export const selectFilter = (filter: string): ISelectFilter => ({
  type: actionTypes.SELECT_FILTER,
  filter,
});

export interface ISelectFilter {
  type: actionTypes.SELECT_FILTER;
  filter: string;
}

export const clearSelectedFilters = (): IClearSelectedFilter => ({
  type: actionTypes.CLEAR_SELECTED_FILTER,
});

export interface IClearSelectedFilter {
  type: actionTypes.CLEAR_SELECTED_FILTER;
}

export interface ISetSearchLocation {
  type: actionTypes.SET_SEARCH_LOCATION;
  location: { label: string } | null;
}

export const setSearchLocation = (
  location: { label: string } | null
): ISetSearchLocation => ({
  type: actionTypes.SET_SEARCH_LOCATION,
  location,
});

export interface ISetLocationSearchString {
  type: actionTypes.SET_SEARCH_STRING;
  searchString: string;
}

export const setLocationSearchString = (
  searchString: string
): ISetLocationSearchString => ({
  type: actionTypes.SET_SEARCH_STRING,
  searchString,
});

export type TravelWalletActions =
  | IFetchTravelOffersDetails
  | IFetchTravelWalletDetailsCallStateFailed
  | ISetTravelWalletOffers
  | ISetTravelWalletCredit
  | IFetchTravelWalletCreditHistory
  | IFetchTravelWalletCreditHistoryCallStateFailed
  | ISetTravelWalletCreditHistory
  | ISetSearchLocation
  | ISetLocationSearchString
  | ISelectFilter
  | IClearSelectedFilter;
