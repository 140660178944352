import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { getRewardsAccounts } from "../../modules/rewards/reducer";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../../modules/travel-wallet/actions/actions";
import {
  getActiveTravelWalletOffers,
  getCreditBreakdown,
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../modules/travel-wallet/reducer";
import { IStoreState } from "../../reducers/types";
import { TravelWalletLanding } from "./component";
import { getAreOfferDetailsLoading } from "./reducer";
import { fetchRewardsAccounts } from "../../modules/rewards/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  allOffers: getTravelWalletOffers(state),
  activeOffers: getActiveTravelWalletOffers(state),
  credit: getTravelWalletCredit(state),
  creditBreakdown: getCreditBreakdown(state),
  areOfferDetailsLoading: getAreOfferDetailsLoading(state),
  rewardsAccounts: getRewardsAccounts(state),
});

const mapDispatchToProps = {
  fetchTravelWalletDetails,
  fetchTravelWalletCreditHistory,
  fetchRewardsAccounts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelWalletLandingConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTravelWalletLanding = connector(
  withRouter(TravelWalletLanding)
);
