import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import { TravelSaleSearchControlConnectorProps } from "./container";
import "./styles.scss";
import { ButtonWrap, Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router";
import * as textConstants from "./textConstants";

interface ITravelSaleSearchControlProps
  extends TravelSaleSearchControlConnectorProps,
    RouteComponentProps {
  className?: string;
}

const AutocompleteHeading = () => (
  <Typography className="autocomplete-heading">
    {textConstants.AUTOCOMPLETE_HEADING_TEXT}
  </Typography>
);

export const TravelSaleSearchControl = (
  props: ITravelSaleSearchControlProps
) => {
  const {
    className,
    searchLocation,
    locationCategories,
    setLocationSearchString,
    setSearchLocation,
  } = props;

  const offerCount = React.useMemo(
    () => locationCategories.flatMap((category) => category.results).length,
    [locationCategories]
  );

  return (
    <div className={clsx("travel-sale-search-control-root", className)}>
      <div className="travel-sale-search-control-input-wrapper">
        <LocationAutocomplete
          className={clsx("destination-auto-complete", "b2b")}
          label={textConstants.AUTOCOMPLETE_INPUT_LABEL_TEXT(offerCount)}
          customIcon={
            <Icon
              name={IconName.MagnifyingGlass}
              ariaLabel=""
              aria-hidden={true}
            />
          }
          getOptionSelected={() => true}
          popperClassName="travel-sale-autocomplete-popper"
          topContent={<AutocompleteHeading />}
          noOptionsText={
            <Box className="no-options-content">
              <AutocompleteHeading />
              <Typography className="no-options-text" variant="body1">
                {textConstants.NO_RESULTS_TEXT}
              </Typography>
            </Box>
          }
        />
        {searchLocation && (
          <ButtonWrap
            className="destination-autocomplete-clear-button"
            onClick={() => {
              setSearchLocation(null);
              setLocationSearchString("");
            }}
          >
            <Typography className="destination-autocomplete-clear-label">
              {textConstants.CLEAR_SEARCH_TEXT}
            </Typography>
            <Icon
              name={IconName.XCircle}
              className="destination-autocomplete-clear-icon"
            />
          </ButtonWrap>
        )}
      </div>
      <hr />
    </div>
  );
};
