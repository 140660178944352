export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/travel-offers";

export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_TRAVEL_CREDITS = `${PATH_TERMS}travel-credits/`;

export const PATH_TRAVEL_SALE = "/travelsale/";

export const PATH_FLIGHTS = BASE_PATH_HOME + "flights/";
export const PATH_FLIGHTS_SEARCH = `${PATH_FLIGHTS}search/`;
export const PATH_HOTELS = BASE_PATH_HOME + "hotels/";
export const PATH_CARS = BASE_PATH_HOME + "cars/";
export const PATH_CARS_SEARCH = `${PATH_CARS}search/`;
export const PATH_HOTEL_AVAILABILITY = PATH_HOTELS + "availability/";
export const PATH_PREMIUM_STAYS = BASE_PATH_HOME + "premium-stays/";
export const PATH_PREMIUM_VACATION_RENTALS =
  PATH_PREMIUM_STAYS + "vacation-rentals/";
export const PATH_EXPERIENCES = BASE_PATH_HOME + "experiences/";
