import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { WalletTravelCreditBalanceCardConnectorProps } from "./container";
import "./styles.scss";
import { CurrencyFormatters, TravelCreditBalanceCard } from "halifax";
import { ClientContext } from "../../../../App";

import {
  CREDIT_BALANCE_APPLICABILITY_TEXT,
  CREDIT_BALANCE_DESCRIPTION,
  CREDIT_BALANCE_TEXT,
} from "./textConstants";
import {
  CREDIT_HISTORY_BALANCE_TEXT,
  CREDIT_HISTORY_MODAL_LIST_HEADER,
  CREDIT_HISTORY_MODAL_SUBHEADER,
  VIEW_ACTIVITY_CTA,
  VIEW_CREDIT_HISTORY_DESCRIPTION,
} from "../../textConstants";

export interface IWalletTravelCreditBalanceCardProps
  extends WalletTravelCreditBalanceCardConnectorProps,
    RouteComponentProps {
  isTravelCreditHistoryExperiment?: boolean;
  isAnnualTravelCreditsExperiment?: boolean;
  onViewCreditHistory?: () => void;
}

export const WalletTravelCreditBalanceCard = ({
  credit,
  creditBreakdown,
  isTravelCreditHistoryExperiment,
  isAnnualTravelCreditsExperiment,
  creditTransactionHistory,
  rewardsAccounts,
  onViewCreditHistory,
}: IWalletTravelCreditBalanceCardProps) => {
  const firstName =
    React.useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  return (
    <TravelCreditBalanceCard
      description={CREDIT_BALANCE_DESCRIPTION(firstName)}
      balanceText={CREDIT_BALANCE_TEXT(credit?.amount)}
      applicabilityText={CREDIT_BALANCE_APPLICABILITY_TEXT}
      showCreditHistory={
        isTravelCreditHistoryExperiment && !!creditTransactionHistory.length
      }
      travelCreditHistoryProps={
        isTravelCreditHistoryExperiment && !!creditTransactionHistory.length
          ? {
              modalContentProps: {
                header: CREDIT_BALANCE_DESCRIPTION(firstName),
                balanceText: CREDIT_HISTORY_BALANCE_TEXT(
                  CurrencyFormatters.getSymbol(credit?.amount.currency),
                  CurrencyFormatters.get(credit?.amount.currency).format(
                    (credit?.amount?.amount && credit?.amount?.amount * -1) || 0
                  )
                ),
                subHeader: CREDIT_HISTORY_MODAL_SUBHEADER,
                creditListHeader: CREDIT_HISTORY_MODAL_LIST_HEADER,
              },
              viewCreditHistoryDescription: VIEW_CREDIT_HISTORY_DESCRIPTION,
              viewHistoryCTAText: VIEW_ACTIVITY_CTA,
              creditTransactionsHistory: creditTransactionHistory,
            }
          : undefined
      }
      isMobile
      credit={credit}
      creditBreakdown={creditBreakdown}
      rewardsAccounts={rewardsAccounts}
      isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
      onViewCreditHistory={onViewCreditHistory}
    />
  );
};
