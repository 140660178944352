import { put } from "redux-saga/effects";
import { WalletHistoryResponse } from "redmond";
import { actions } from "../../actions";
import { fetchTravelWalletCreditHistory } from "../../../../api/v1/travel-wallet/fetchTravelWalletCreditHistory";

export function* fetchTravelWalletCreditHistorySaga(
  _: actions.IFetchTravelWalletCreditHistory
) {
  try {
    const walletCreditHistory: WalletHistoryResponse =
      yield fetchTravelWalletCreditHistory();
    yield put(
      actions.setTravelWalletCreditHistory(walletCreditHistory.credits)
    );
  } catch (e) {
    yield put(actions.fetchTravelWalletCreditHistoryCallStateFailed());
  }
}
