import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { IconName, Icon, ButtonWrap } from "halifax";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import { MobileLocationSearchInputConnectorProps } from "./container";
import "./styles.scss";

export interface IMobileLocationSearchInputProps
  extends MobileLocationSearchInputConnectorProps {
  onClick?: () => void;
  autoFocus?: boolean;
  modalOpen?: boolean;
}

export const MobileLocationSearchInput = (
  props: IMobileLocationSearchInputProps
) => {
  const {
    setLocationSearchString,
    locationSearchString,
    searchLocation,
    setSearchLocation,
    onClick,
    autoFocus,
    locationCategories,
    modalOpen,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>();

  const offerCount = React.useMemo(
    () => locationCategories.flatMap((category) => category.results).length,
    [locationCategories]
  );

  React.useEffect(() => {
    if (onClick) {
      inputRef.current?.blur();
    }
  }, [modalOpen, onClick]);

  return (
    <div className="mobile-travel-sale-location-search-input-root">
      <div className="mobile-travel-sale-location-search-input-field">
        <Icon name={IconName.MagnifyingGlass} />
        <TextField
          className={clsx("travel-sale-location-autocomplete", "mobile")}
          onChange={(e) => setLocationSearchString(e.target.value)}
          value={locationSearchString}
          label={textConstants.AUTOCOMPLETE_INPUT_LABEL(offerCount)}
          InputProps={{
            disableUnderline: true,
          }}
          inputRef={inputRef}
          autoFocus={autoFocus}
          onClick={onClick}
        />
        {searchLocation ? (
          <ButtonWrap
            className="destination-autocomplete-clear-button"
            onClick={() => {
              setSearchLocation(null);
              setLocationSearchString("");
            }}
          >
            <Typography className="destination-autocomplete-clear-label">
              {textConstants.CLEAR_SEARCH_TEXT}
            </Typography>
            <Icon
              name={IconName.XCircle}
              className="destination-autocomplete-clear-icon"
            />
          </ButtonWrap>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
