import React from "react";
import { Typography } from "@material-ui/core";
import {
  ActionLink,
  ButtonWrap,
  CloseButtonIcon,
  MobilePopoverCard,
} from "halifax";
import * as textConstants from "./textConstants";
import { MobileLocationSearchModalConnectorProps } from "./container";
import "./styles.scss";
import { MobileLocationSearchInput } from "../MobileLocationSearchInput";

export interface IMobileLocationSearchModalProps
  extends MobileLocationSearchModalConnectorProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const MobileLocationSearchModal = (
  props: IMobileLocationSearchModalProps
) => {
  const {
    open,
    setOpen,
    setLocationSearchString,
    setSearchLocation,
    searchLocation,
    filteredLocationCategories,
  } = props;

  const nonEmptyLocationCategories = React.useMemo(
    () =>
      filteredLocationCategories.filter(
        (category) => category.results.length > 0
      ),
    [filteredLocationCategories]
  );

  const handleClose = () => {
    if (!searchLocation) {
      setLocationSearchString("");
    }
    setOpen(false);
  };

  const handleDestinationTap = (destination: string) => {
    setSearchLocation({ label: destination });
    setOpen(false);
  };

  return (
    <MobilePopoverCard
      open={open}
      className={"mobile-travel-sale-search-location-popup"}
      onClose={handleClose}
      headerElement={
        <div className="modal-header-container">
          <Typography className="header-title">
            {textConstants.MODAL_TITLE}
          </Typography>
          <ActionLink
            className="modal-close-button"
            onClick={handleClose}
            content={<CloseButtonIcon className="close-button-icon" />}
            label="Close"
            showTappableArea
          />
        </div>
      }
    >
      <div className="mobile-travel-sale-search-location-modal-content">
        <MobileLocationSearchInput autoFocus />
        <div className="travel-sale-autocomplete-list-root">
          <Typography variant="h4" className="list-heading">
            {textConstants.DESTINATION_LIST_HEADING}
          </Typography>
          <div className="travel-sale-autocomplete-list-wrapper">
            {nonEmptyLocationCategories.length === 0 && (
              <Typography className="no-results-text">
                {textConstants.NO_RESULTS_TEXT}
              </Typography>
            )}
            {nonEmptyLocationCategories.map((locationCategory) => (
              <div className="travel-sale-autocomplete-list-group">
                <Typography className="list-group-heading">
                  {locationCategory.label}
                </Typography>

                <ul className="list-group-options">
                  {locationCategory.results.map((destination) => (
                    <li className="list-group-item">
                      <ButtonWrap
                        onClick={() => handleDestinationTap(destination.label)}
                        className="list-group-item-button"
                      >
                        {destination.label}
                      </ButtonWrap>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MobilePopoverCard>
  );
};
