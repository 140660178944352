import { connect, ConnectedProps } from "react-redux";

import { TravelSaleSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import {
  getLocationCategories,
  getSearchLocation,
} from "../../../../modules/travel-wallet/reducer";
import { actions } from "../../../../modules/travel-wallet/actions";

const mapStateToProps = (state: IStoreState) => ({
  searchLocation: getSearchLocation(state),
  locationCategories: getLocationCategories(state),
});

export const mapDispatchToProps = {
  setSearchLocation: actions.setSearchLocation,
  setLocationSearchString: actions.setLocationSearchString,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelSaleSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedTravelSaleSearchControl = withRouter(
  connector(TravelSaleSearchControl)
);
