import axios from "axios";
import { RewardsAccountsResponse } from "redmond";
import { config } from "../../config";

import { rewardsApiPrefix } from "../paths";

const END_POINT: string = `${rewardsApiPrefix}/accounts`;

export const fetchRewardsAccounts = (): Promise<RewardsAccountsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        END_POINT,
        {},
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
