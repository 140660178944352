export const apiVersionPrefix: string = "/api/v0";

export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;
export const agentPortalChargeBookingFeeApi: string = `${apiVersionPrefix}/agentPortal/chargeBookingFee`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;
