import { all } from "redux-saga/effects";
import { sagas as rewards } from "../modules/rewards";
import { sagas as wallet } from "../modules/travel-wallet";

export function* rootSaga() {
  yield all([
    rewards.watchFetchRewardsAccounts(),
    wallet.watchFetchTravelWalletDetails(),
    wallet.watchFetchTravelWalletCreditHistory(),
  ]);
}
